// src/pages/about/BecomeAMember.js

import React from "react";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";

const MembershipContainer = styled.div`
  max-width: 1000px;
  margin: 120px auto 50px; /* espace en haut et en bas */
  text-align: justify;

  @media (max-width: 600px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Title = styled(Typography)`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
`;

const Description = styled(Typography)`
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const BecomeAMember = () => {
  return (
    <MembershipContainer>
      <Title variant="h4" component="h1">
        Become a Member
      </Title>

      <Description variant="body1" gutterBottom>
        Join <strong>SHALOM</strong> and be part of a community that celebrates 
        culture, fosters friendships, and creates opportunities. As a member, 
        you'll have access to exclusive events, networking opportunities, and more.
      </Description>

      <Description variant="body1" gutterBottom>
        Ready to join us? Simply fill out the form below, and we’ll be in touch with you soon!
      </Description>

      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          href="https://forms.gle/GMV7TAnAtgbyrhot8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sign Up
        </Button>
      </ButtonContainer>
    </MembershipContainer>
  );
};

export default BecomeAMember;
