import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
    text-align: justify;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
    }
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
`;

const Description = styled.p`
    color: #555;
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const Image = styled.img`
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const Conference2 = () => {
    const { id } = useParams();

    // Here, you can either dynamically fetch the information
    // or hard-code it as shown in this example.
    const event = {
        id: id,
        title: "Conference: History of International Relations in the Middle East",
        description: `
            We are pleased to invite you to an exceptional conference
            on the history of international relations in the Middle East,
            presented by Prof. Denis Charbit, specialist in sociology and political science.

            This conference will trace the evolution of geopolitical dynamics in the region,
            from the fall of the Ottoman Empire to the establishment of the State of Israel in 1948.
            Prof. Charbit will analyze the treaties, negotiations, and power dynamics
            that shaped the region during this pivotal period.

            📍 Location: Room CE 1 104, EPFL
            📅 Date: March 5, 2025, at 6:30 PM

            Don't miss this opportunity to explore the history of a region
            at the heart of international relations!

            For participants from outside EPFL, registration is required via the following link:
            https://forms.gle/N1n9S8Lf8aGM4D9B8
        `,
        ImageURL: process.env.PUBLIC_URL + "/conference2.jpeg", 
        // If you don't have an image, leave it empty or add a placeholder
    };

    return (
        <Container>
            <Title>{event.title}</Title>
            <Description>{event.description}</Description>
            {event.ImageURL && (
                <Image src={event.ImageURL} alt={event.title} />
            )}
        </Container>
    );
};

export default Conference2;
