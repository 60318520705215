import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
    text-align: justify;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
      }
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
`;

const Description = styled.p`
    color: #555;
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const Image = styled.img`
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const Evenement = () => {
    const { id } = useParams();
  
    // Dummy data for activity details (replace with actual data fetching)
    const evenement = {
      id,
      title: "Shabbat open to everyone",
      description: `Join us on the 4th of April for a shabbat SHALOM ✨💙.
      Price: 20 CHF (regular), 16 CHF (member).`,
      registrationLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSdelF7qFIg4rS_fWh5PPdZfs9wAbo_E1tb6eZNX3DwuMdRT2Q/viewform?pli=1",
      ImageURL: `${process.env.PUBLIC_URL}/shabbat.jpg`,
    };
  
    return (
      <Container>
        <Title>{evenement.title}</Title>
        <Description>
          {evenement.description}
          <br />
          <a href={evenement.registrationLink} target="_blank" rel="noopener noreferrer">
            Click here to register
          </a>
        </Description>
        <Image src={evenement.ImageURL} alt={evenement.title} />
      </Container>
    );
  };
  
  export default Evenement;