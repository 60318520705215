import React from 'react';
import { Grid, Card, Typography, Button, Chip, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const events = [
  {
    title: "Chalets de l'Avent",
    description: "Come and enjoy a warm cup of hot chocolate and delicious souvganiotes with us!",
    link: "/chalets2024",
    date: "11/12/2024",
  },
  {
    title: "Conference",
    description: "The Life Sciences Start-up Ecosystem in Israel. A conference by Dr. Laurent Choppe.",
    link: "/conference1",
    date: "05/12/2024",
  },
  {
    title: "New Year (Rosh Hashana)",
    description: "Dip some apples in honey and wish for a sweet year ahead!",
    link: "/rosh_hashana2024",
    date: "01/10/2024",
  },
  {
    title: "Semaine des Associations 2024",
    description: "We're thrilled to be part of \"EPFL Associations Week 2024\"! Stop by our stand to discover more about what we do and get involved with our community!",
    link: "/semaineDesAssos",
    date: "24/09/2024",
  },
  {
    title: "Welcome Apero",
    description: "The perfect way to meet the new members and create a sense of community!",
    link: "/welcomeApero",
    date: "19/09/2024",
  },
  {
    title: "Vivapoly 2024",
    description: "We are happy to announce that we will take part in the 2024 Vivapoly edition! Come and see us at our stand!",
    link: "/vivapEvent",
    date: "20/05/2024",
  },
  {
    title: "Creation of the Association",
    description: "SHALOM - the Israeli Association has been established! We are excited to develop it further and create enriching experiences and opportunities for Israeli students, as well as individuals interested in Israeli culture.",
    link: "/creation",
    date: "08/04/2024",
  },
  // --- NOUVEAU : Événement conférence ---
  {
    title: "Conference",
    // Description raccourcie
    description: "History of International Relations in the Middle East: From the Ottoman Empire to 1948. A conference by Prof. Denis Charbit.",
    link: "/conference2",
    date: "05/03/2025",
  },
  {
    title: "Shabbat open to everyone",
    // Description raccourcie
    description: "Celebrate Shabbat with Shalom !",
    link: "/shabbat",
    date: "04/04/2025",
  },
];


const Activities = () => {
  const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(`20${year}`, month - 1, day);
  };

  const sortedEvents = [...events].sort((a, b) => formatDate(b.date) - formatDate(a.date));

  const isPast = (dateStr) => {
    return formatDate(dateStr) < new Date();
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', px: { xs: 2, sm: 3 }, py: 8 }}>
      <Box sx={{ 
        mb: 6, 
        display: 'flex', 
        alignItems: 'baseline', 
        justifyContent: 'space-between',
        pt: 4  // Added top padding
      }}>
        <Typography 
          variant="h2" 
          sx={{ 
            fontSize: { xs: '2rem', sm: '2.5rem' }, 
            fontWeight: 'bold',
            pl: 2  // Added left padding
          }}
        >
          Associative Life
        </Typography>
        <Chip
          label={`${events.length} Events`}
          variant="outlined"
          sx={{ fontSize: '0.9rem' }}
        />
      </Box>

      <Grid container spacing={3}>
        {sortedEvents.map((event, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease',
                opacity: isPast(event.date) ? 0.75 : 1,
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 3
                }
              }}
            >
              <Box sx={{ 
                p: 3, 
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '150px' // Ensures minimum card height
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 1 }}>
                  <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                    {event.title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                    <CalendarTodayIcon sx={{ fontSize: '1rem' }} />
                    <Typography variant="body2">{event.date}</Typography>
                  </Box>
                </Box>
                <Typography 
                  variant="body1" 
                  color="text.secondary" 
                  sx={{ mb: 3, flexGrow: 1 }} // Takes up available space
                >
                  {event.description}
                </Typography>
                <Button
                  component={Link}
                  to={event.link}
                  variant={isPast(event.date) ? "outlined" : "contained"}
                  fullWidth
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    mt: 'auto', // Pushes button to bottom
                    '& .MuiSvgIcon-root': {
                      transition: 'transform 0.2s',
                    },
                    '&:hover .MuiSvgIcon-root': {
                      transform: 'translateX(4px)',
                    }
                  }}
                >
                  {isPast(event.date) ? 'View Past Event' : 'Learn More'}
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Activities;